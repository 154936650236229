import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import './App.css'; // Import the CSS file

const RentPrices = () => {
    const [data, setData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [uniqueLocations, setUniqueLocations] = useState([]);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // ascending order by default
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch data from the backend
        const url = `${process.env.REACT_APP_HOST}/api/rent-prices`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setData(data.results);
                setFilteredData(data.results);
                const locations = [...new Set(data.results.map(item => item['Περιοχή']))];
                setUniqueLocations(locations.map(location => ({ value: location, label: location })));
            });
    }, []);

    useEffect(() => {
        if (selectedRegions.length === 0) {
            setFilteredData(data);
        } else {
            const filtered = data.filter(item => selectedRegions.some(region => region.value === item['Περιοχή']));
            setFilteredData(filtered);
        }
    }, [selectedRegions, data]);

    const handleRegionChange = (selectedOptions) => {
        setSelectedRegions(selectedOptions);
    };

    const handleSort = (column) => {
        const order = column === sortBy && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(column);
        setSortOrder(order);
        const sortedData = [...filteredData].sort((a, b) => {
            if (order === 'asc') {
                return a[column] - b[column];
            } else {
                return b[column] - a[column];
            }
        });
        setFilteredData(sortedData);
    };

    // Custom Option component to add checkboxes
    const Option = (props) => {
        return (
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    style={{
                        marginRight: 10,
                        accentColor: props.isSelected ? 'white' : 'initial', // Change color when selected
                    }}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        );
    };

    // Custom styles for react-select components
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#4caf50' : state.isFocused ? '#f0f0f0' : 'white',
            color: state.isSelected ? 'white' : 'black',
            boxShadow: state.isFocused ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
            '&:hover': {
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add light shadow on hover
            },
        }),
        control: (provided) => ({
            ...provided,
            borderColor: '#ccc',
            '&:hover': {
                borderColor: '#b3b3b3',
            },
        }),
        checkbox: (provided) => ({
            ...provided,
            backgroundColor: 'black', // Change checkbox color to black
        }),
    };

    
    return (
        <div className="container">
            <p className="main-page-button">
                <button className="go-back-btn" onClick={() => navigate("/")}>
                    <span className="fas fa-arrow-left">Επιστροφή στην Αρχική</span>
                </button>
            </p>
            <h2 className="title">Τιμές ενοικίου</h2>
            {data ? (
                <div>
                    <div className="filter-container">
                        <h3>Φιλτράρισμα Περιοχών</h3>
                        <Select
                            isMulti
                            options={uniqueLocations}
                            value={selectedRegions}
                            onChange={handleRegionChange}
                            placeholder="Επιλέξτε περιοχές..."
                            className="multi-select"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            styles={customStyles} // Apply custom styles
                        />
                    </div>
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort("Περιοχή")}>Περιοχή</th>
                                <th onClick={() => handleSort("Α' τρίμηνο 2024 (€/τμ)")}>Α' τρίμηνο 2024 (€/τμ)</th>
                                <th onClick={() => handleSort("Α' τρίμηνο 2023 (€/τμ)")}>Α' τρίμηνο 2023 (€/τμ)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData && filteredData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item['Περιοχή']}</td>
                                    <td>{item["Α' τρίμηνο 2024 (€/τμ)"]}</td>
                                    <td>{item["Α' τρίμηνο 2023 (€/τμ)"]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>Φόρτωση...</p>
            )}
            <footer className="footer">
                <p>@Copyright 2024</p>
            </footer>
        </div>
    );
};

export default RentPrices;