import React, { useState } from 'react';
import './App.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';

function RankingPersonalDetails() {
    const navigate = useNavigate();
    // State variables to hold form data
    const [formData, setFormData] = useState({
        table_type: 'pe70',
        last_name: '',
        first_name: '',
        father_name: '',
        id_card_number: ''
    });

    // State variable to hold API results
    const [results, setResults] = useState(null);

    // Handler for form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Make POST request to your FastAPI endpoint
            const url = `${process.env.REACT_APP_HOST}/api/personal-ranking`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const responseData = await response.json();
            // Update results state with response data
            setResults(responseData.results);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Handler for form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="container">
            <p className="main-page-button">
                <button className="go-back-btn" onClick={() => navigate("/")}>
                    <span className="fas fa-arrow-left">Επιστροφή στην Αρχική</span>
                </button>
            </p>
            <h1 className="title">Εύρεση θέσης πίνακα</h1>
            <form onSubmit={handleSubmit} className="form">
                <div className="form-group">
                    <label>
                        Τύπος πίνακα:
                        <select name="table_type" value={formData.table_type} onChange={handleChange} required>
                            <option value="pe70">ΠΕ70</option>
                            <option value="pe71_main">ΠΕ70-71 ΚΥΡΙΟΣ</option>
                            <option value="pe71_secondary">ΠΕ70-71 ΕΠΙΚΟΥΡΙΚΟΣ</option>
                            {/* Add other options here */}
                        </select>
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Επώνυμο: <span className="required">*</span>
                        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Όνομα:
                        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Πατρώνυμο:
                        <input type="text" name="father_name" value={formData.father_name} onChange={handleChange} />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        Α.Δ.Τ.:
                        <input type="text" name="id_card_number" value={formData.id_card_number} onChange={handleChange} />
                    </label>
                </div>
                <button type="submit" className="submit-btn">Εύρεση</button>
            </form>

            {/* Display results */}
            {results && (
                <div class="results">
                    <h2>Αποτελέσματα</h2>
                    {results.map((result, index) => (
                        <div key={index} className="result">
                            <p>Επώνυμο: {result.last_name}</p>
                            <p>Όνομα: {result.first_name}</p>
                            <p>Πατρώνυμο: {result.father_name}</p>
                            <p>Θέση πίνακα: {result.ranking}</p>
                        </div>
                    ))}
                </div>
            )}
            <footer className="footer">
                <p>@Copyright 2024</p>
            </footer>
        </div>
    );
}

export default RankingPersonalDetails;
