import React, { useState, useEffect } from 'react';
import './App.css'; // Import CSS file
import { useNavigate } from 'react-router-dom';

function RankingPerScore() {
    const [data, setData] = useState(null);
    const [uniqueDates, setUniqueDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedValue, setSelectedValue] = useState(''); // State for user-entered value
    const [uniqueLocations, setUniqueLocations] = useState(null);
    const [submitted, setSubmitted] = useState(false); // State to track if form submitted
    const navigate = useNavigate();

    useEffect(() => {
        const url = `${process.env.REACT_APP_HOST}/api/states-min-max`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setData(data.results);
                const dates = new Set();
                const locations = new Set();
                data.results.forEach(locationData => {
                    Object.keys(locationData).forEach(location => {
                        locations.add(location);
                        Object.keys(locationData[location]).forEach(date => {
                            dates.add(date);
                        });
                    });
                });
                setUniqueDates(Array.from(dates).sort());
                setUniqueLocations(Array.from(locations).sort());
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleValueChange = (event) => {
        const value = event.target.value;
        // Allow only integers
        if (!isNaN(value) && value !== '') {
            setSelectedValue(value);
        }
    };

    const handleSubmit = () => {
        // Send POST request with selected value and selected date
        if (selectedValue !== '' && selectedDate !== '') {
            const url = `${process.env.REACT_APP_HOST}/api/possible_placement`
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ranking: selectedValue,
                    phase: selectedDate
                })
            })
                .then(response => response.json())
                .then(data => {
                    // Handle response data
                    console.log(data);
                    setData(data.results);
                    const locations2 = new Set();
                    const dates2 = new Set();
                    data.results.forEach(locationData2 => {
                        Object.keys(locationData2).forEach(location => {
                            locations2.add(location);
                            Object.keys(locationData2[location]).forEach(date => {
                                dates2.add(date);
                            });
                        });
                    });
                    setSubmitted(true);
                })
                .catch(error => console.error('Error sending POST request:', error));
        }
    };

    return (
        <div className="container">
            <p className="main-page-button">
                <button className="go-back-btn" onClick={() => navigate("/")}>
                    <span className="fas fa-arrow-left">Επιστροφή στην Αρχική</span>
                </button>
            </p>
            <h2 className="title">Μόρια ανά περιοχή ανά φάση</h2>
            {data ? (
                <div>
                    <div className="form">
                        <div className="form-group score">
                            <label>
                                Θέση πίνακα:
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Εισάγετε την θέση σας στον πίνακα"
                                    value={selectedValue}
                                    onChange={handleValueChange}
                                />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                <select className="select" onChange={handleDateChange}>
                                    <option value="">Ημερομηνία φάσης προσλήψεων</option>
                                    {uniqueDates.map(date => (
                                        <option key={date} value={date}>{date}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <button className="submit-btn" onClick={handleSubmit}>Υποβολή</button>
                    </div>
                    {submitted && data.length > 0 ? (
                        <div className="results">
                            {data.map((locationData2, index) => (
                                <div key={index} className="result">
                                    {Object.entries(locationData2).map(([location, info]) => (
                                        <div key={location}>
                                            <h3>{location}</h3>
                                            <p>Πρώτος στον πίνακα: {info.table_min}</p>
                                            <p>Τελευταίος στον πίνακα: {info.table_max}</p>
                                            <p>Συνολικές προσλήψεις: {info.total}</p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>Δεν υπάρχουν αποτελέσματα.</p>
                    )}
                </div>
            ) : (
                <p>Φόρτωση...</p>
            )}
            <footer className="footer">
                <p>@Copyright 2024</p>
            </footer>
        </div>
    );
}

export default RankingPerScore;
