import React, { useState } from 'react';
import "./App.css"; // Import CSS file
import { useNavigate } from 'react-router-dom';

function AppointmentDate() {
    const [minDate, setMinDate] = useState(null);
    const [ranking, setRanking] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        const url = `${process.env.REACT_APP_HOST}/api/insert-date`
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ranking: parseInt(ranking) // Convert to integer before sending
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.results && Object.keys(data.results).length > 0) {
                    const { min_date } = data.results;
                    if (min_date === -1) {
                        setErrorMessage('No valid ranking of table');
                    } else {
                        setMinDate(min_date);
                        setShowResults(true);
                        setErrorMessage('');
                    }
                } else {
                    setErrorMessage('No results found');
                }
            })
            .catch(error => {
                console.error('Error submitting data:', error);
            });
    };

    return (
        <div className="container">
            <p className="main-page-button">
                <button className="go-back-btn" onClick={() => navigate("/")}>
                    <span className="fas fa-arrow-left">Επιστροφή στην Αρχική</span>
                </button>
            </p>
            <h2 className="title">Πρόσληψη βάσει σειράς πίνακα</h2>
            <div className="form">
                <div className="form-group">
                    <label htmlFor="rankingInput">Θέση πίνακα:</label>
                    <input
                        id="rankingInput"
                        type="text"
                        placeholder="Εισάγετε την θέση σας στον πίνακα"
                        value={ranking}
                        onChange={(e) => setRanking(e.target.value)}
                    />
                </div>
                <button className="submit-btn" onClick={handleSubmit}>
                    Υποβολή
                </button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {showResults && !errorMessage && (
                <div className="result">
                    {minDate ? (
                        <p>Έγινε πρόσληψη στις: {minDate}</p>
                    ) : (
                        <p>No results found</p>
                    )}
                </div>
            )}
            <footer className="footer">
                <p>@Copyright 2024</p>
            </footer>
        </div>
    );
}

export default AppointmentDate;