// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RankingPerState from './rankingPerState'; // Import the Component1
import RankingPerScore from "./rankingPerScore";
import RankingPersonalDetails from "./rankingPersonalDetails";
import MainPage from "./mainPage";
import AppointmentDate from "./restComponents";
import RankingAboveMe from "./peopleAboveMe";
import RentPrices from "./rentPrices"

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<MainPage />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-per-state" element={<RankingPerState />} /> {/* Route for page 1 */}
                <Route exact path="/appointment-date" element={<AppointmentDate />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-per-score" element={<RankingPerScore />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-personal-details" element={<RankingPersonalDetails />} /> {/* Route for page 1 */}
                <Route exact path="/ranking-above-me" element={<RankingAboveMe />} />
                <Route exact path="/rent-prices" element={<RentPrices />} />
            </Routes>
        </Router>
    );
}

export default App;