import React, { useState, useEffect } from 'react';
import './App.css'; // Import CSS file
import { useNavigate } from 'react-router-dom';

function RankingAboveMe() {
    const [data, setData] = useState(null);
    const [uniqueDates, setUniqueDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedValue, setSelectedValue] = useState(''); // State for user-entered value
    const [uniqueLocations, setUniqueLocations] = useState(null);
    const [submitted, setSubmitted] = useState(false); // State to track if form submitted
    const [isValidInput, setIsValidInput] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const url = `${process.env.REACT_APP_HOST}/api/places`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setData(data.results);
                const locations = new Set();
                data.results.forEach(location => {
                    locations.add(location);
                });
                setUniqueLocations(Array.from(locations).sort());
                console.log(setUniqueLocations)
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleLocationChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleValueChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        // Regular expression to match only integers
        const isValid = /^\d+$/.test(value);
        setIsValidInput(isValid);
    };

    const handleSubmit = () => {
        // Send POST request with selected value and selected date
        if (selectedValue !== '' && selectedDate !== '') {
            const url = `${process.env.REACT_APP_HOST}/api/place-ranking`
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ranking: selectedValue,
                    place: selectedDate
                })
            })
                .then(response => response.json())
                .then(data => {
                    // Handle response data
                    console.log(data);
                    setData(data.results);
                    const dates2 = new Set();
                    data.results.forEach(dateData2 => {
                        Object.keys(dateData2).forEach(date2 => {
                            dates2.add(date2);
                        });
                    });
                    setSubmitted(true);
                })
                .catch(error => console.error('Error sending POST request:', error));
        }
    };

    return (
        <div className="container">
            <p className="main-page-button">
                <button className="go-back-btn" onClick={() => navigate("/")}>
                    <span className="fas fa-arrow-left">Επιστροφή στην Αρχική</span>
                </button>
            </p>
            <h2 className="title">Άτομα πριν την κατάταξή μου</h2>
            {data ? (
                <div>
                    <div className="form">
                        <div className="form-group score">
                            <label>
                                Θέση πίνακα:
                                <input
                                    className="input"
                                    type="text"
                                    placeholder="Εισάγετε την θέση σας στον πίνακα"
                                    value={selectedValue}
                                    onChange={handleValueChange}
                                />
                                {!isValidInput && <p className="error-message">Please enter integers only.</p>}
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                <select className="select" onChange={handleLocationChange}>
                                    <option value="">Τοποθεσία προτίμησης</option>
                                    {uniqueLocations.map(location => (
                                        <option key={location} value={location}>{location}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <button className="submit-btn" onClick={handleSubmit}>Υποβολή</button>
                    </div>
                    {submitted && data && (
                        <div className="results">
                            {data.map((locationData2, index) => (
                                <div key={index} className="result">
                                    {Object.entries(locationData2).map(([location, info]) => (
                                        <div key={location}>
                                            <h3>{location}</h3>
                                            <p>Άτομα πριν από μένα: {info.total_smaller}</p>
                                            <p>Συνολικές προσλήψεις: {info.total}</p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <p>Φόρτωση...</p>
            )}
            <footer className="footer">
                <p>@Copyright 2024</p>
            </footer>
        </div>
    );
}

export default RankingAboveMe;
