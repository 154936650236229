import React from 'react';
import { Link } from 'react-router-dom';
import "./App.css"

function MainPage() {
    return (
        <div className="container">
            <h1 className="title">Χρήσιμα εργαλεία για αναπληρωτές ΠΕ70</h1>
            <div className="form-group">
                <Link to="/ranking-per-state" className="text-link">
                    <button className="submit-btn">Μόρια ανά περιοχή ανά φάση</button>
                </Link>
            </div>
            <div className="form-group">
                <Link to="/ranking-per-score" className="text-link">
                    <button className="submit-btn">Έλεγχος περιοχών βάσει θέσης πίνακα
                    </button>
                </Link>
            </div>
            <div className="form-group">
                <Link to="/ranking-personal-details" className="text-link">
                    <button className="submit-btn">Εύρεση θέσης πίνακα</button>
                </Link>
            </div>
            <div className="form-group">
                <Link to="/appointment-date" className="text-link">
                    <button className="submit-btn">Πρόσληψη βάσει σειράς πίνακα</button>
                </Link>
            </div>
            <div className="form-group">
                <Link to="/ranking-above-me" className="text-link">
                    <button className="submit-btn">Έλεγχος συνολικού αριθμού ανθρώπων που προηγούνται</button>
                </Link>
            </div>
{/*             <div className="form-group">
                <Link to="/rent-prices" className="text-link">
                    <button className="submit-btn">Τιμές ενοικίου</button>
                </Link>
            </div> */}
        </div>
    );
}

export default MainPage;