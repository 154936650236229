import React, { useState, useEffect } from 'react';
import './App.css'; // Import CSS file
import { useNavigate } from 'react-router-dom';

function RankingPerState() {
    const [data, setData] = useState(null);
    const [uniqueDates, setUniqueDates] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [uniqueLocations, setUniqueLocations] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const url = `${process.env.REACT_APP_HOST}/api/states-min-max`
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setData(data.results);
                const dates = new Set();
                const locations = new Set();
                data.results.forEach(locationData => {
                    Object.keys(locationData).forEach(location => {
                        locations.add(location);
                        Object.keys(locationData[location]).forEach(date => {
                            dates.add(date);
                        });
                    });
                });
                setUniqueDates(Array.from(dates).sort());
                setUniqueLocations(Array.from(locations).sort());
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        if (selectedDate === "all") {
            setSelectedDates(uniqueDates);
        } else {
            setSelectedDates([selectedDate]);
        }
    };

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value);
    };

    const filterDataByDates = (data, dates) => {
        return data.filter(locationData => {
            if (!selectedLocation) return true;
            return Object.keys(locationData)[0] === selectedLocation;
        }).map(locationData => {
            const filteredInfo = {};
            Object.keys(locationData[Object.keys(locationData)[0]]).forEach(date => {
                if (dates.includes(date)) {
                    filteredInfo[date] = locationData[Object.keys(locationData)[0]][date];
                }
            });
            return { [Object.keys(locationData)[0]]: filteredInfo };
        });
    };

    return (
        <div className="container">
            <p className="main-page-button">
                <button className="go-back-btn" onClick={() => navigate("/")}>
                    <span className="fas fa-arrow-left">Επιστροφή στην Αρχική</span>
                </button>
            </p>
            <h2 className="title">Μόρια ανά περιοχή ανά φάση</h2>
            {data ? (
                <div>
                    <div className="form">
                        <div className="form-group">
                            <label>
                                Διεύθυνση Πρωτοβάθμιας εκπαίδευσης:
                                <select className="select" onChange={handleLocationChange}>
                                    <option value="">Επιλέξτε...</option>
                                    {uniqueLocations.map(location => (
                                        <option key={location} value={location}>{location}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                Ημερομηνία φάσης προσλήψεων:
                                <select className="select" onChange={handleDateChange}>
                                    <option value="">Επιλέξτε...</option>
                                    <option value="all">Όλες οι ημερομηνίες</option>
                                    {uniqueDates.map(date => (
                                        <option key={date} value={date}>{date}</option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                    {selectedDates.length > 0 && selectedLocation && (
                        <div className="data-container">
                            {filterDataByDates(data, selectedDates).map(locationData => (
                                <div key={Object.keys(locationData)[0]} className="result">
                                    <h2 className="location-name">{Object.keys(locationData)[0]}</h2>
                                    <ul className="location-data">
                                        {Object.entries(locationData[Object.keys(locationData)[0]]).map(([date, info]) => (
                                            <li key={date} className="list-data">
                                                <div>Ημερομηνία: {date}</div>
                                                <div>Πρώτος στον πίνακα: {info.table_min}</div>
                                                <div>Τελευταίος στον πίνακα: {info.table_max}</div>
                                                <div>Συνολικές προσλήψεις: {info.total}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <p>Φόρτωση...</p>
            )}
            <footer className="footer">
                <p>@Copyright 2024</p>
            </footer>
        </div>
    );
}

export default RankingPerState;